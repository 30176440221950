import { useEffect } from 'react';

/**
 * 只调用一次方法的hook
 * @param callback
 */
export const useCallOncePerSession = (callback: (args?: any) => void) => {
  useEffect(() => {
    if (!sessionStorage.getItem('myMethodCalled')) {
      callback();
      sessionStorage.setItem('myMethodCalled', 'true');
    }
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('myMethodCalled');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // 清除事件监听器
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [callback]);
};
