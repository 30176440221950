import "../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css\",\"source\":\"Ll8xOWJhY2hpMCB7CiAgbWluLXdpZHRoOiAyMjBweDsKfQouXzE5YmFjaGkxIHsKICBtYXJnaW4tbGVmdDogMTZweDsKfQouXzE5YmFjaGkyIHsKICBwYWRkaW5nLXJpZ2h0OiA4cHg7Cn0KLl8xOWJhY2hpMyB7CiAgZm9udC1zaXplOiB2YXIoLS1hZmZpbmUtZm9udC14cyk7CiAgZmxleC13cmFwOiBub3dyYXA7Cn0KLl8xOWJhY2hpM1tkYXRhLWFjdGl2ZT0idHJ1ZSJdIHsKICBjb2xvcjogdmFyKC0tYWZmaW5lLXByaW1hcnktY29sb3IpOwp9Ci5fMTliYWNoaTQgewogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47CiAgZ2FwOiA4cHg7CiAgZm9udC13ZWlnaHQ6IDUwMDsKICBmb250LXNpemU6IHZhcigtLWFmZmluZS1mb250LXhzKTsKfQouXzE5YmFjaGk1IHsKICBmb250LXdlaWdodDogNDAwOwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdGV4dC1zZWNvbmRhcnktY29sb3IpOwp9Ci5fMTliYWNoaTYgewogIHBhZGRpbmc6IDRweCAxMnB4OwogIGhlaWdodDogMjhweDsKICBmb250LXNpemU6IHZhcigtLWFmZmluZS1mb250LXhzKTsKICBmb250LXdlaWdodDogNTAwOwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdGV4dC1zZWNvbmRhcnktY29sb3IpOwogIG1hcmdpbi1ib3R0b206IDRweDsKfQouXzE5YmFjaGk3IHsKICBwYWRkaW5nOiA0cHggMTJweDsKICBoZWlnaHQ6IDI4cHg7CiAgZm9udC1zaXplOiB2YXIoLS1hZmZpbmUtZm9udC14cyk7Cn0KLl8xOWJhY2hpOCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LXdyYXA6IHdyYXA7CiAgbWF4LXdpZHRoOiAyMDBweDsKICBnYXA6IDhweDsKICBwYWRkaW5nOiA0cHggMTJweDsKfQouXzE5YmFjaGk5IHsKICBjb2xvcjogdmFyKC0tYWZmaW5lLXRleHQtZGlzYWJsZS1jb2xvcik7Cn0KLl8xOWJhY2hpOVtkYXRhLWFjdGl2ZT0idHJ1ZSJdIHsKICBjb2xvcjogdmFyKC0tYWZmaW5lLXRleHQtcHJpbWFyeS1jb2xvcik7Cn0=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var currentGroupType = '_19bachi5';
export var headerDisplayButton = '_19bachi1';
export var listOption = '_19bachi6';
export var menu = '_19bachi0';
export var properties = '_19bachi7';
export var propertiesWrapper = '_19bachi8';
export var propertyButton = '_19bachi9';
export var subMenuItem = '_19bachi3';
export var subMenuTrigger = '_19bachi2';
export var subMenuTriggerContent = '_19bachi4';