import "../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81UzY7aMBC+8xSjlSrBwShh2e3We+mrTOIJuDi2ZQ+QbcW7V3GAxLvQanvqKcn8eL4fT5ZalRifC/g1A9iS3mxZQlkUX15nAEeteDt+Kh29wTcJjaGuD/RPoXSgmrWzEmpn9q1NGWdZRP2TJBwwzIXAptGWRIpXGGnRV3kX9dAZyCDrA/XRCuvdJri9Ve+ax4TwQbcY3kTtjAuL19lpthyIlIlICkvQdktB8yS9Sml2XkLRzzLU8Pk1DNyLm0xHpI3uSPUhNHpjhWZqo4SaLFPowz/2kXXTI7NMliVEjzWJivhIlLTxqJS2Gwnls++gXBW+myB8zJx4WvnuhhMf4Fzb16n9A/7BGbFBL2GdzXuaCpbJzdTxB6H/Ym1sx5rjhUORNE3HKapdwAG6dZYyPda9HC8ZuueEbjJytfZnQ3SvuKADWY4ScM9u2rf0BrX9HLdr97fUN2p+OL7m23HYZsCLf722AJULioIIqPQ+Xk5KsVGgynUiblG54yV2RYr/j3vlyncwXGaAFjtx985my36DPwe0l9pRPCiWjxEIY8ZfYsMUziqcN+7hIZ+HVXRmz+d5zK59v+aVcfVusmc1mnreAwcB68J3i6n/xfJpIHnfcYP1TpTFIkNqHc+lwcjCNYLfPC0S7BbDRlsxAXaafW9JaYRYByILaBXMM0X7LRi6s//a+3/LxY3TWLg+F16pX3yc1Lzcr7kNTds/QPt677R7Fp1mp9+dklhAlgYAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = 'id1as61';
export var draggableHeader = 'id1as63';
export var hideInSmallScreen = 'id1as68';
export var hideInWideScreen = 'id1as67';
export var iconButton = 'id1as66';
export var menu = 'id1as69';
export var menuItem = 'id1as6a';
export var root = 'id1as60';
export var topNav = 'id1as62';
export var topNavLink = 'id1as65';
export var topNavLinks = 'id1as64';