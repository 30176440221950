/**
 * set the theme of the d3
 */
export const setD3Theme = () => {
  // const theme = localStorage.getItem('theme');
  // const html = document.querySelector('html');
  // if (!theme || theme === 'light') {
  //   // fix 首次进入affine时没注入d3样式的问题
  //   localStorage.setItem('theme', 'dark');
  //   html?.classList.remove('virtual-d3-dark-theme');
  //   html?.classList.add('virtual-d3-light-theme');
  // } else {
  //   html?.classList.add('virtual-d3-dark-theme');
  //   html?.classList.remove('virtual-d3-light-theme');
  // }
};
