import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WbW/bIBD+nl+BKlVqpFE5zcsyou1/TNM0ETjbrBgswE26qf99AuwEO07abNW+2Wfu7rnnHu58/2NWLcpGFxn6PUGIC1tL+kxQLmG/mSDEtNSGoCdq7jCmeS4UYAd7h2sjKmqecTgw9UdLEEXpCFou6uDqQ2BbGqEeCcq8ZSe4KwmaZdmtf6VSFAoLB5UlyDoDjpXe7gxVVjihFUFbyh4LoxvFYyaU3T/YD0jXlAn3HN68i34Ck0u9I6gUnIMK0BtjPXYOOW2k20xeJveHaknpXULNwxSDasPBQ5lpjG+cOoqZFOyRbiV8dqaB7yFkl7rWQjkwPbdZONGRtVhGsrbacDDYUC4aS9A6Wk/a0aOMQQyelnAePM6FlMCnMZvvDOWesN75ClTTfgkHK7rHbdfmqyyCqoTqbA/RllT3EKqrddc/A5I68QQxmCmEwhJyRxBuS+yI6EShGyeFAoKUVsGrppwLVbRu65gvUs8NLYqE+iMKsoVcG4jN0MqBcgTd3Gx62OjWatm4kMXpmqBlhBD0l2tTkfgoqYOvd3iZ3QZOIpBU0It+Ke1rKuP46Yx4XxFgLSmDUkueyPBEMA8xZxs6wHsDS0EeKVeHbgzjzc7GS4xCFSc5QtgjrPvl3wYagzlvYXZyHAN9vK/oCyK5MNZhVgrJo1J76pqtBnIOPpKOupiIYT1wmaeHCMrQ8rq7XNDa34ZB1MX4eD4T43T05lo5bMUvGIgr2Es874+25Xi2EJYLAyyqmmnZVOoER8zuqHGbPhOR3t5UYVSyO3/3EQ5FT7s8oXsI7UrhANuasjASdobW3txYMNiCBOa6SZHAX0XRjeyEizTYano4s2sVtsqyCzASoGEpHlOClKK2wh7JsSDzZM0lcD+ehXt2+1pgWvH+/r1Y296+kdl/qGN9nUZfV+Q4AYJplZY90HoC6NP7AWr5u6ohifIHy65bpb0fogQ4/X/AT//jRoaHt3iBpEI5re7ypdxeVdPPxjqRe1jt8g4YQPFXWs7eL0ucbjjM49X4L+3LH6pmnGM/CwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1m4huogb';
export var dateCell = '_1m4huoga';
export var dndCell = '_1m4huog2';
export var dragPageItemOverlay = '_1m4huog1';
export var iconCell = '_1m4huog8';
export var operationsCell = '_1m4huogc';
export var root = '_1m4huog0';
export var selectionCell = '_1m4huog4';
export var tagsCell = '_1m4huog9';
export var titleCell = '_1m4huog5';
export var titleCellMain = '_1m4huog6';
export var titleCellPreview = '_1m4huog7';
export var titleIconsWrapper = '_1m4huog3';