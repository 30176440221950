import "../../component/src/components/auth-components/onboarding-page.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/onboarding-page.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227bMAx9z1cIBQY0DyrkXNzMxYC97huGYZAt2lYjS4Yk57Kh/z5IvkR2kqbZ9pIEFHUORR6SefoZreN1s2wI+j1DKKXZttCqkQxnSiidoB3VjxjTPOcScHBca15RfWzd5i8zhErgRWkTFBGyK52BcVMLekxQLuDgDO4bM64hs1zJBGVKNJV0J2oHOhdqn6CSMwbyZfY2expC+86opZgbXNFMGQwCMquV/PJgdQMPP3zkFdUFlwna1J4qVZqBxpoy3pjB2oeYUZE9+jgRRlFcH+Yjwsgj1pQxLgtsVZ2gxapF6I26BRrMyvD2TcbybHt0Nn+P3JkIKnghMbdQmdYdg2Tj4IZs7Llkan8lH6PoyYXQva2gdeITMKJYhAgJWpDrj0yVtarq0P5ePQH50pPfkbLXxlieOyxpQbrqgrSgz7J5Mg8v25D6gBbEfUYxmWRh1enqgPec2TJBMZm6rP85VlPTDHAKdg8gh4os4zbjFZe41+xqPWWPPXuupMU5rbg4Juhb/0RvNfwXTGrg7SWO5icnexSQIKl0RcVg3XesMfGVFe5uH8lyqpfne9NwrnJjqbYTRXYIhXZzIRoxbj7GqNX+I3R9F7dHpeZy6xUdEH72hFf0FCS7hRpdNLvCX77YEpr2zTK/FEE/1/qm3UzQ6UkC14qdUgPzs7quyfh9qQfqhL5eTKWWnXgmyvgIdwDEgjwaEPm4HoEjtDNoGDk0NUo0FkbzVCoJ7WTirhYYdiCt6e0BWh7siPHsDpyKe2UciCeAKW/XxHIrLhTl+UKzrabw/DZ8iePRRl6SboLfbO3FtLVfr+TkPfqDGf8f6JrZZ6v73QktIuTTxbF42nz/aatsr7aghYPFBjIl2bW7yY4bboHdj/G1AsYpMpkGkIhKhh6DhRIRJ565h53sv2BJue3kRq7fVi4hb6H7auq+cv6+sqPl1af67WZUz/HmLKi0YzkH+wPYIFZvQQoAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var button = '_1565u3uc';
export var buttonWrapper = '_1565u3u8';
export var checkBox = '_1565u3u9';
export var content = '_1565u3u5';
export var disableButton = '_1565u3ue';
export var footer = '_1565u3u2';
export var header = '_1565u3u1';
export var input = '_1565u3ub';
export var label = '_1565u3ua';
export var layout = '_1565u3u0';
export var link = '_1565u3uk';
export var linkGroup = '_1565u3uj';
export var onboardingContainer = '_1565u3u4';
export var openAFFiNEButton = '_1565u3ud';
export var optionsWrapper = '_1565u3u7';
export var question = '_1565u3u6';
export var scrollableContainer = '_1565u3u3';
export var thankContainer = '_1565u3ug';
export var thankText = '_1565u3ui';
export var thankTitle = '_1565u3uh';
export var windowsAppButton = '_1565u3uf';