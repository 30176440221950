import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { isHasHideLeft } from './utils';

const isHideLeft = isHasHideLeft();
export const APP_SIDEBAR_OPEN = 'app-sidebar-open';
export const isMobile = !BUILD_CONFIG.isElectron && window.innerWidth < 768;
export const appSidebarOpenAtom = isHideLeft
  ? atom(false)
  : atomWithStorage(APP_SIDEBAR_OPEN, !isMobile);
export const appSidebarFloatingAtom = atom(isMobile);

export const appSidebarResizingAtom = atom(false);
export const appSidebarWidthAtom = isHideLeft
  ? atom(248)
  : atomWithStorage('app-sidebar-width', 248 /* px */);

// doc list sidebar
export const DOC_LIST_SIDEBAR_OPEN = 'doc-list-sidebar-open';
export const docListSidebarOpenAtom = atomWithStorage(
  DOC_LIST_SIDEBAR_OPEN,
  !isMobile
);
export const docListSidebarWidthAtom = isHideLeft
  ? atom(248)
  : atomWithStorage('app-sidebar-width', 248 /* px */);
