import { MenuItem } from '@affine/component/ui/menu';
import { useAutoCreateWorkspace } from '@affine/core/components/hooks/affine/use-auto-create-workspace';
import { useCallOncePerSession } from '@affine/core/components/page-list/use-call-once-per-session';
import { AuthService } from '@affine/core/modules/cloud';
import { useI18n } from '@affine/i18n';
import { ImportIcon, PlusIcon } from '@blocksuite/icons/rc';
import {
  FeatureFlagService,
  useLiveData,
  useService,
  WorkspacesService,
} from '@toeverything/infra';

import * as styles from './index.css';

export const AddWorkspace = ({
  onAddWorkspace,
  onNewWorkspace,
}: {
  onAddWorkspace?: () => void;
  onNewWorkspace?: () => void;
}) => {
  const t = useI18n();
  const workspaceManager = useService(WorkspacesService);
  const workspaces = useLiveData(workspaceManager.list.workspaces$);
  const featureFlagService = useService(FeatureFlagService);
  const enableLocalWorkspace = useLiveData(
    featureFlagService.flags.enable_local_workspace.$
  );
  const authService = useService(AuthService);
  const loginStatus = authService.session.status$;
  const autoCreateWorkspace = useAutoCreateWorkspace();

  const currentUrl = new URL(window.location.href);

  // 思核云登录完带着token跳转回来后，先不创建工作区，等到跳回 / 后，再创建
  if (!currentUrl.searchParams.has('authorizationToken')) {
    useCallOncePerSession(() => {
      // 等登录状态变为 authenticated 后，再创建工作区
      loginStatus.subscribe(status => {
        if (status === 'authenticated' && !workspaces.length) {
          autoCreateWorkspace()
          .then(res => {
            console.log('res', res);
          })
          .catch(err => {
            console.error('err', err);
          });
        }
      });
    });
  }
  return (
    <div>
      {BUILD_CONFIG.isElectron && (
        <MenuItem
          block={true}
          prefixIcon={<ImportIcon />}
          onClick={onAddWorkspace}
          data-testid="add-workspace"
          className={styles.ItemContainer}
        >
          <div className={styles.ItemText}>
            {t['com.affine.workspace.local.import']()}
          </div>
        </MenuItem>
      )}
      <MenuItem
        block={true}
        prefixIcon={<PlusIcon />}
        onClick={onNewWorkspace}
        data-testid="new-workspace"
        className={styles.ItemContainer}
      >
        <div className={styles.ItemText}>
          {enableLocalWorkspace
            ? t['com.affine.workspaceList.addWorkspace.create']()
            : t['com.affine.workspaceList.addWorkspace.create-cloud']()}
        </div>
      </MenuItem>
    </div>
  );
};
