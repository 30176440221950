import { CreateWorkspaceDialogService } from '@affine/core/modules/create-workspace';
import { buildShowcaseWorkspace } from '@affine/core/utils/first-app-data';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { track } from '@affine/track';
import { useService, WorkspacesService } from '@toeverything/infra';
import { useNavigateHelper } from '../use-navigate-helper';

/**
 *
 * @returns 自动创建本地工作区文档
 */
export const useAutoCreateWorkspace = () => {
  const workspacesService = useService(WorkspacesService);
  const createWorkspaceDialogService = useService(CreateWorkspaceDialogService);
  const { jumpToPage } = useNavigateHelper();

  const handleCreateFolder = async () => {
    const workspaceFlavour = WorkspaceFlavour.AFFINE_CLOUD;
    track.$.$.$.createWorkspace({ flavour: workspaceFlavour });
    const name = '我的文档库';

    const { meta, defaultDocId } = await buildShowcaseWorkspace(
      workspacesService,
      workspaceFlavour,
      name
    );
    createWorkspaceDialogService.dialog.callback({ meta, defaultDocId });
    jumpToPage(meta.id, 'all'); // jumpTo currentWorkspace

    createWorkspaceDialogService.dialog.close();
  };

  return handleCreateFolder;
};
