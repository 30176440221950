import "../../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW25KjIBB9z1fwslU7D6Q0k2Qz5GO2iLTaMwgWYHR2a/99SzSRqLlN1Tzawunu04cDy9/xRxE3tYnI3wUhXGKmKDooLCMJKAdmvyCk5EKgyhiJo7JpA/oIJpW6ZiRHIUC1MYG2lPyTkVSCX/ReWYfpJ020cqAcI7bkCdADuBq6LQeefGRGV0owcuTmJ6U8TVEBHX7Q0mDBTYsitXnZL/4tlqeaY19zqpWjFv/ACMPHc7p52Z8W1YBZ7hjZRFEb84ijTQ4aRy0kWokw6Ux3V6jKeMnIrmMp7/Ot+u/KgqEWJCSOEaUVXHSz8t3MFYWJVnOloJK+0VsVBQlerybwXd8geu13PkrB/bkPeiqbE1kP13Vn4o31aw7aCDDUcIGVHYSbaFkVivoprbtQt7KrxWqJYiTFDmjI/hXRElLwhtYoXM7Ia3Ss25DvbjhJICWWFm37q87RAfW8tUqpDS8vBLXqSvfjPwXj5fYXFL7JytiWy1LjRAQbP8q+kpFOdyEhZ+o20Q9PuoSG2tyg+mAkusDcesw5T3i2xQD016PHIVBD7HkJQHZfAFlFI5C3efV7QgQaSBxqxXpp7Qd246hj7qz23QiYe+Dz7yjYu3rbXnPaAOAwX1noQb7KltxQR+eU27LpSSOkQHXW0rqP3bOUpHPgNkdm2iLjyXXRtVVwk6FihA6h07nrjJAQXblWz0Pg5jG3xTC3lBcovR/mYNAFbhJEgtPZ/3SGK1tyA8rdZTphrJQ8gVxLAeaqqoI1cz4qnlVS6xrnmUSTy5dXTl8kgIuTSJvwLJbaYodvQHKHRxhnWJ0yDAIKsNPHije6vnE3nKWxbpW3vfSf19U4/WMimPjdxMPWT94xYdssb/n0zU81dAHlF85jHNGiA/HlGzhdCrT8IOFmNZWy4EJGJgLJvsH+8++wf7xhbfEtd5p5f8wY1/v9p2P/kHjq5TiZ4H96PV5kYAsAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowDownSmallIcon = '_1km1xwr7';
export var buttonText = '_1km1xwrj';
export var docListHeader = '_1km1xwr0';
export var docListHeaderTitle = '_1km1xwr1';
export var rightButtonGroup = '_1km1xwri';
export var searchIcon = '_1km1xwr8';
export var searchInput = '_1km1xwrc';
export var tagIcon = '_1km1xwrg';
export var tagIndicator = '_1km1xwr5';
export var tagLabel = '_1km1xwr6';
export var tagSelectorItem = '_1km1xwrf';
export var tagSelectorItemText = '_1km1xwrh';
export var tagSelectorTagsScrollContainer = '_1km1xwre';
export var tagSticky = '_1km1xwr4';
export var tagsEditorRoot = '_1km1xwr9';
export var tagsEditorSelectedTags = '_1km1xwrb';
export var tagsEditorTagsSelector = '_1km1xwrd';
export var tagsMenu = '_1km1xwra';
export var titleCollectionName = '_1km1xwr3';
export var titleIcon = '_1km1xwr2';